import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { RichText } from 'prismic-reactjs'

import { Modal } from './Modal'

const withEnlarge = Comp => props => {
  const [enlargedImage, setEnlargedImage] = useState()

  function handleEnlarge({ caption = null, image, title = null }) {
    if (typeof window !== 'undefined') document.body.style.overflowY = 'hidden'
    setEnlargedImage({ caption, image, title })
  }

  function closeModal() {
    if (typeof window !== 'undefined') document.body.style.overflowY = ''
    setEnlargedImage()
  }

  return (
    <>
      <Comp handleEnlarge={handleEnlarge} {...props} />
      {enlargedImage && (
        <Modal className="cursor-pointer" onClick={closeModal}>
          <div className="p-4 max-h-full h-screen pointer-events-none flex flex-col justify-center relative">
            {enlargedImage.title && (
              <div className="mb-4 px-4 text-center">
                <p className="text-white font-heading text-xl lg:text-2xl uppercase">{enlargedImage.title}</p>
              </div>
            )}
            <Img
              className="max-h-full"
              fluid={enlargedImage.image}
              objectFit="contain"
              objectPosition="50% 50%"
            />
            {enlargedImage.caption && (
              <div className="mt-4 px-4 text-center max-w-4xl mx-auto">
                <p className="text-white font-heading text-xl">{enlargedImage.caption}</p>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

export { withEnlarge }
