import React from 'react'

const Modal = ({ children, className, ...props }) => (
  <div className={`fixed top-0 left-0 z-50 bg-darken-900 h-screen w-full ${className}`} {...props}>
    {children}
  </div>
)

Modal.defaultProps = {
  className: '',
}

export { Modal }
