import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { Image } from '../components/Image'
import { Layout } from '../components/Layout'
import { RawContentSection } from '../components/RawContentSection'
import { SEO } from '../components/SEO'
import { withEnlarge } from '../components/withEnlarge'

import { toExtrasData } from '../transform'

const Extra = withEnlarge(
  ({ bg = 'white', extra: { description, galleryImages, mainImage, title }, handleEnlarge }) => (
    <section className={`w-full mx-auto bg-${bg} py-8 md:py-12 lg:py-16 px-4`}>
      <div className="container max-w-xl">
        <h2 className="text-3xl">{RichText.asText(title)}</h2>
        <div className="mt-4 md:mt-8">
          <Image image={mainImage} alt={RichText.asText(title)} title={RichText.asText(title)} />
        </div>
        <div className="mt-4 md:mt-8">
          <RawContentSection content={<RichText render={description} />} />
        </div>
        {galleryImages.length > 0 && (
          <div class="flex flex-wrap -mx-2 mt-4 md:mt-8">
            {galleryImages.map(image => (
              <div
                key={image.src}
                className="w-1/3 md:w-1/6 p-2 cursor-pointer hover:opacity-75"
                onClick={() => handleEnlarge({ image })}
              >
                <Image
                  image={{ ...image, aspectRatio: 1 }}
                  alt={RichText.asText(title)}
                  title={RichText.asText(title)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
)

const Extras = ({
  data: {
    prismic: { extras_page },
  },
}) => {
  if (!extras_page) return null

  const { description, extras, title } = toExtrasData(extras_page)

  return (
    <Layout>
      <SEO title="Preamps, Pickups, Softcases" />
      <section className="bg-white px-4 py-8 md:py-12 lg:py-16 border-b-4 border-gray-200">
        <div className="container max-w-6xl">
          <h1 className="text-5xl md:text-6xl max-w-xl mx-auto">{RichText.asText(title)}</h1>
          <div className="mt-4">
            <RawContentSection content={<RichText render={description} />} />
          </div>
        </div>
      </section>
      <>
        {extras.map((extra, i) => (
          <Extra key={RichText.asText(extra.title)} bg={i % 2 === 1 ? 'white' : 'gray-200'} extra={extra} />
        ))}
      </>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      extras_page(lang: "en-nz", uid: "extras") {
        ...extrasPage
      }
    }
  }
`

export default Extras
